import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Visit,
  TitleContent,
  ExternalLink,
  WorkedWith,
} from "../components/parts"

const Contextually = () => (
  <Layout title="Contextual.ly">
    <Title heading="Contextual.ly" sub="Read articles with context" />
    <Overview
      project="contextually"
      text={[
        "Given an article, this plugin identifies critical people, places, and incidents. It also searches Wikipedia to present an overview of them.",
        "Instead of being constantly distracted by ads and without having to look up information in a new tab frequently, users can read articles with context.",
      ]}
    />
    <TitleContent title="Screenshots">
      <ExternalLink
        href="https://www.newyorker.com/magazine/2015/02/23/shape-things-come"
        label="New yorker article: Johnathan Ive"
      />
      <img
        src="/images/contextually/screenshot2.png"
        alt="contextually-screenshot"
      />
      <hr />
      <ExternalLink
        href="https://www.newyorker.com/magazine/2015/02/23/shape-things-come"
        label="Guardian article: Paris climate change"
      />
      <img
        src="/images/contextually/screeshot3.png"
        alt="contextually-screenshot"
      />
    </TitleContent>
    <Visit project="contextually" />
    <Toolset project="contextually" />
    <WorkedWith project="contextually" />
  </Layout>
)

export default Contextually
